import { IHistoryTransactionGroup } from "../types/historyTransaction";
import { TransactionStatus } from "../constants/historyTransaction";
import dateFormat from "dateformat";
import { map, filter, groupBy, flattenDeep, sortBy } from "lodash-es";
import { FullTransaction } from "../legacyGraphql/graphql";

export class HistoryTransactionFactory {
  static groupByDateTransactions = (transactions: FullTransaction[]) => {
    const sortedTransactions = sortBy(transactions, (t) => {
      return new Date(t.createdAt);
    }).reverse();
    const pendingTransactions = filter(sortedTransactions, {
      status: TransactionStatus.Pending,
    });
    const completedTransactions = filter(
      sortedTransactions,
      (t) => t.status !== TransactionStatus.Pending,
    );

    const formatTransactionsDate = map(completedTransactions, (c) => {
      const now = new Date().getTime();
      const timeGap = (now - new Date(c.createdAt).getTime()) / 86400000;
      const todayDate = new Date(now).getDate();
      const createdDate = new Date(c.createdAt).getDate();
      if (timeGap < 1 && todayDate === createdDate) {
        return { ...c, timeline: "Today" };
      }
      const specificDate = dateFormat(c.createdAt, "mmm d, yyyy");
      return { ...c, timeline: specificDate };
    });

    const completedListGroupByCreatedDate = groupBy(
      formatTransactionsDate,
      (o) => o.timeline,
    );

    return {
      pending: pendingTransactions,
      ...completedListGroupByCreatedDate,
    };
  };

  static getCountTransaction = (transactions?: IHistoryTransactionGroup) => {
    return (
      transactions?.pending?.length ||
      0 + flattenDeep(map(Object.values(transactions || {})))?.length
    );
  };

  static findIndexTransaction = (
    transactionId: string,
    transactions?: IHistoryTransactionGroup,
  ) => {
    if (!transactions) return 0;
    return flattenDeep(map(Object.values(transactions))).findIndex(
      (transaction) => transaction.entryId === transactionId,
    );
  };
}
