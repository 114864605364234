/* eslint-disable */
export type Maybe<T> = T;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any };
};

/** Denotes the type of user achievement */
export enum Achievement_Type {
  BattleFriendsPlayed = "battle_friends_played",
  BattleStreak = "battle_streak",
  BattlesWon = "battles_won",
  Challenger = "challenger",
  Completionist = "completionist",
  Followers = "followers",
  LoginStreak = "login_streak",
  TimePlayed = "time_played",
  TournamentsParticipation = "tournaments_participation",
  WeeklyGlParticipant = "weekly_gl_participant",
  WeeklyGlWinner = "weekly_gl_winner",
}

export type AchievementUserNotificationData = {
  __typename: "AchievementUserNotificationData";
  /** Achievement balance that user got */
  balance: Scalars["Int"]["output"];
  /** Value to enable or disable achievement notification */
  isEndScreenReward: Scalars["Boolean"]["output"];
  /** Achievement type that user got */
  type: Achievement_Type;
};

/** Denotes the Battle payout data */
export type BattlePayout = {
  __typename: "BattlePayout";
  /** Currency to be used for Battle Payout */
  coinType: CoinType;
  /** Prize value for the loser (toRank = 2) */
  coinsLoser: Scalars["Int"]["output"];
  /** Prize value for the winner (toRank = 1) */
  coinsWinner: Scalars["Int"]["output"];
};

/** Data for battle notifications */
export type BattleUserNotificationData = {
  __typename: "BattleUserNotificationData";
  /** Datetime at which the battle will expire, can be null for certain battle notifications */
  expiryTime: Maybe<Scalars["DateTimeISO"]["output"]>;
  /** Game ID */
  gameId: Scalars["String"]["output"];
  /** User informations about the host */
  host: User;
  /** Match ID */
  matchId: Scalars["UUID"]["output"];
  /** Match State */
  matchState: MatchState;
  /** User information about the opponent */
  opponent: User;
};

export enum BucketType {
  Assets = "assets",
  Data = "data",
}

/** Denotes the rewards for a challenge */
export type ChallengeRewards = {
  __typename: "ChallengeRewards";
  /** Currency to be used for Challenge rewards */
  coinType: CoinType;
  /** Gems received when challenge is completed from the second time onwards */
  coinsCompletion: Scalars["Int"]["output"];
  /** Gems received when user fails to complete a challenge */
  coinsFailure: Scalars["Int"]["output"];
  /** Gems received when challenge is completed for the first time */
  coinsFirstTimeCompletion: Scalars["Int"]["output"];
};

export enum CoinType {
  Basic = "basic",
  Gems = "gems",
  SpendableGems = "spendable_gems",
}

/** Denotes a coin wallet */
export type CoinWallet = {
  __typename: "CoinWallet";
  /** Coin balance */
  balance: Scalars["Int"]["output"];
  /** Coin type */
  itemType: CoinType;
  /** User Id */
  userId: Scalars["String"]["output"];
  /** Wallet Type */
  walletType: WalletType;
};

export type Config = {
  __typename: "Config";
  /** Free coins that can be claimed by the user */
  freeCoins: Maybe<FreeCoins>;
  /** Prize pools for battles and challenges */
  prizePools: PrizePools;
  /** Tier Levels */
  tierLevels: Array<TierLevel>;
};

export type CreateTenantInput = {
  /** Tenant's Front End URL */
  appUrl: Scalars["String"]["input"];
  /** Cognito Client ID used for login by the tenant */
  clientId: Scalars["String"]["input"];
  /**
   * ID of the tenant
   * Format: `{tenant_name}{country_code}`
   * - `{tenant_name}` should be name of the tenant. Ex: `dialog`
   * - `{country_code} should in ISO 3166-1 alpha-2 format. Ex: `lk`
   * - Example: `dialoglk`
   */
  id: Scalars["String"]["input"];
  /** Default language code of the tenant. Defaults to `en` */
  languageCode: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Name of the tenant
   * Recommended Format: `FriendlyTenantName FriendlyCountryName`
   * Example: `Dialog Sri Lanka`
   */
  name: Scalars["String"]["input"];
  /**
   * Short name for the tenant
   * - Restricted to 3 characters
   * - Example: `dgl`, `vil`, etc.
   */
  shortCode: Scalars["String"]["input"];
  /** Tenant status which indicates if tenant is live or is disabled. Defaults to LIVE */
  status: InputMaybe<TenantStatus>;
};

/** Represents a featured card. */
export type FeaturedCard = {
  __typename: "FeaturedCard";
  /** The creation date time of the featured card */
  createdAt: Scalars["DateTimeISO"]["output"];
  /** The start date time of the featured card */
  endDate: Maybe<Scalars["DateTimeISO"]["output"]>;
  /** Unique identifier for the featured card, typically a UUID */
  id: Scalars["UUID"]["output"];
  /** Multilingual image URLs for the featured card, where keys represent language codes */
  imageUrl: Scalars["JSON"]["output"];
  /** Type of link associated with the featured card (e.g., internal, external, promotional) */
  linkType: LinkType;
  /** Ordering position of the featured card in a list or display sequence */
  position: Scalars["Int"]["output"];
  /** The destination URL where the card will redirect users when clicked */
  redirectUrl: Scalars["String"]["output"];
  /** The start date time of the featured card */
  startDate: Maybe<Scalars["DateTimeISO"]["output"]>;
  /** The updation date time of the featured card */
  updatedAt: Scalars["DateTimeISO"]["output"];
};

/** Input type of Featured Cards */
export type FeaturedCardInput = {
  /** End date and time */
  endDate: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  /** Language-specific image URLs */
  imageUrl: Scalars["JSON"]["input"];
  /** Type of link (e.g., internal, external) */
  linkType: Scalars["String"]["input"];
  /** Display position in a list */
  position: Scalars["Int"]["input"];
  /** URL to redirect upon click */
  redirectUrl: Scalars["String"]["input"];
  /** Start date and time */
  startDate: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type FeaturedCardOutput = PaginatedQueryOutput & {
  __typename: "FeaturedCardOutput";
  items: Array<FeaturedCard>;
  total: Scalars["Int"]["output"];
};

/** Data for follow notifications */
export type FollowUserNotificationData = {
  __typename: "FollowUserNotificationData";
  /** user doing the following */
  followerUser: User;
};

export type FreeCoins = {
  __typename: "FreeCoins";
  /** Amount of free coins that can be claimed */
  amount: Scalars["Int"]["output"];
  /** Currency in which the free coins would be given in */
  coinType: CoinType;
};

export type Game = {
  __typename: "Game";
  /** Category of the game (e.g., racing, arcade puzzle) */
  category: Scalars["String"]["output"];
  /** Dynamic metadata which gives additional information about the game */
  data: Maybe<Scalars["String"]["output"]>;
  /** The description of the game */
  description: Scalars["String"]["output"];
  /** Indicates if the game is featured game */
  featured: Scalars["Boolean"]["output"];
  /** Indicates if the game has a BATTLE type moment */
  hasBattle: Scalars["Boolean"]["output"];
  /** Indicates if the game has a CASUAL type moment */
  hasSolo: Scalars["Boolean"]["output"];
  /** Indicates if the game has a OPEN status tournament */
  hasTournament: Scalars["Boolean"]["output"];
  /** Unique identifier for the game */
  id: Scalars["String"]["output"];
  /** Mode of rotation for the game */
  rotationMode: RotationMode;
  /** Current status of the game */
  status: GameStatus;
  /** The title of the game */
  title: Scalars["String"]["output"];
  /** Type of the game */
  type: GameType;
};

export enum GameStatus {
  Disabled = "DISABLED",
  Draft = "DRAFT",
  Live = "LIVE",
  Upcoming = "UPCOMING",
}

export enum GameType {
  Embed = "EMBED",
  Html = "HTML",
  Stream = "STREAM",
}

export type GamesOutput = PaginatedQueryOutput & {
  __typename: "GamesOutput";
  items: Array<Game>;
  total: Scalars["Int"]["output"];
};

export type Health = {
  __typename: "Health";
  status: HealthStatus;
};

export enum HealthStatus {
  Error = "ERROR",
  Ok = "OK",
}

export type InventoriesFilters = {
  /** UUID of the bundle inventory to list all the items of the bundle */
  bundleInventoryId: InputMaybe<Scalars["UUID"]["input"]>;
  /** Filter by inventory categories if provided */
  categories: InputMaybe<Array<InventoryCategory>>;
  /** Return only bundles in response */
  restrictToBundles: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Return only free items in response */
  restrictToFree: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Return only non-bundle items in response */
  restrictToItems: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter to return Inventory items with given status */
  status: InputMaybe<Array<InventoryStatus>>;
};

/** Filters for fetching inventories. */
export type InventoriesInput = {
  /** Additional input filters */
  filters: InputMaybe<InventoriesFilters>;
  /** Pagination input */
  page: InputMaybe<PaginatedQueryInput>;
};

export type InventoriesOutput = PaginatedQueryOutput & {
  __typename: "InventoriesOutput";
  items: Array<Inventory>;
  total: Scalars["Int"]["output"];
};

export type Inventory = {
  __typename: "Inventory";
  /** Indicates whether the inventory should be applied immediately upon purchase. */
  applyImmediately: Scalars["Boolean"]["output"];
  /** Category of the inventory, categorizing it into a specific group. */
  category: InventoryCategory;
  /** List of costs associated with acquiring the inventory, in different coin types. */
  costs: Maybe<Array<Maybe<InventoryCost>>>;
  /** The timestamp when the inventory was created. */
  createdAt: Scalars["DateTimeISO"]["output"];
  /** Data associated with the inventory */
  data: InventoryData;
  /** Unique identifier for the inventory. */
  id: Scalars["UUID"]["output"];
  /** Indicates whether the inventory is a bundle consisting of multiple items. */
  isBundle: Scalars["Boolean"]["output"];
  /** Indicates if the inventory was purchased by the user */
  isPurchased: Scalars["Boolean"]["output"];
  /** Current status of the inventory, indicating its availability and visibility. */
  status: InventoryStatus;
  /** Title of the inventory. */
  title: Scalars["String"]["output"];
  /** The timestamp when the inventory was last updated. */
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type InventoryAvatarData = {
  __typename: "InventoryAvatarData";
  url: Scalars["String"]["output"];
};

export type InventoryBundleData = {
  __typename: "InventoryBundleData";
  items: Array<Scalars["String"]["output"]>;
  previewUrl: Scalars["String"]["output"];
};

export enum InventoryCategory {
  Avatar = "AVATAR",
  Mixed = "MIXED",
}

export type InventoryCost = {
  __typename: "InventoryCost";
  amount: Scalars["Float"]["output"];
  coinType: CoinType;
};

export type InventoryData = InventoryAvatarData | InventoryBundleData;

export enum InventoryStatus {
  Available = "AVAILABLE",
  Disabled = "DISABLED",
  Hidden = "HIDDEN",
  Restricted = "RESTRICTED",
}

/** Data for leaderboard notifications */
export type LeaderboardUserNotificationData = {
  __typename: "LeaderboardUserNotificationData";
  /** Date at which the leaderboard ends */
  endDate: Scalars["String"]["output"];
  /** Type of the leaderboard i.e. weekly, monthly or contest */
  leaderboardType: Maybe<Scalars["String"]["output"]>;
  /** Rank the user finished at */
  rank: Scalars["Int"]["output"];
  /** Time frame of the leaderboard involved */
  timeFrame: Maybe<TimeFrame>;
};

/** Link type enum */
export enum LinkType {
  /** External links that open a third-party webpage */
  External = "external",
  /** Internal paths for use within the application */
  Internal = "internal",
}

export type ListGamesInput = {
  /** Pagination parameters. */
  page: InputMaybe<PaginatedQueryInput>;
  /** Status of the required games. */
  status: Array<GameStatus>;
  /** When provided will return only the available games for both the provided user and requesting user */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

export enum MatchState {
  Cancelled = "CANCELLED",
  Created = "CREATED",
  Expired = "EXPIRED",
  Played = "PLAYED",
  Playing = "PLAYING",
  Refunded = "REFUNDED",
  Rejected = "REJECTED",
}

export type Message = {
  __typename: "Message";
  message: Scalars["String"]["output"];
};

export type Mutation = {
  __typename: "Mutation";
  claimFreeCoins: Message;
  clearNotifications: Message;
  createTenant: Message;
  followUser: Message;
  generateUploadURL: SignedUrl;
  updateFeaturedCards: Message;
  updateTenant: Message;
  updateUser: Message;
  updateUserMetadata: Message;
};

export type MutationClearNotificationsArgs = {
  notificationIds: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};

export type MutationFollowUserArgs = {
  userId: Scalars["UUID"]["input"];
};

export type MutationGenerateUploadUrlArgs = {
  input: SignedUrlInput;
};

export type MutationUpdateFeaturedCardsArgs = {
  input: UpdateFeaturedCardsInput;
};

export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};

export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
};

export type MutationUpdateUserMetadataArgs = {
  input: UserMetadataInput;
};

export type Pageable =
  | FeaturedCard
  | Game
  | Inventory
  | Tenant
  | Transaction
  | User;

export type PaginatedQueryInput = {
  limit: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaginatedQueryOutput = {
  items: Array<Pageable>;
  total: Scalars["Int"]["output"];
};

export type PrizePools = {
  __typename: "PrizePools";
  /** Battle coins payouts */
  battle: BattlePayout;
  /** Challenge Rewards */
  challenge: ChallengeRewards;
};

export type Query = {
  __typename: "Query";
  config: Config;
  featuredCards: FeaturedCardOutput;
  games: GamesOutput;
  health: Health;
  inventories: InventoriesOutput;
  me: User;
  tenant: Tenant;
  tenants: TenantsOutput;
  user: User;
  users: Maybe<UsersOutput>;
};

export type QueryFeaturedCardsArgs = {
  tenantId: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGamesArgs = {
  input: ListGamesInput;
};

export type QueryInventoriesArgs = {
  input: InputMaybe<InventoriesInput>;
};

export type QueryTenantArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTenantsArgs = {
  page: InputMaybe<PaginatedQueryInput>;
};

export type QueryUserArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryUsersArgs = {
  page: InputMaybe<PaginatedQueryInput>;
};

export enum RotationMode {
  Any = "ANY",
  Landscape = "LANDSCAPE",
  Portrait = "PORTRAIT",
}

export type SignedUrl = {
  __typename: "SignedURL";
  /** The url to get or put objects to S3 */
  url: Scalars["String"]["output"];
};

export type SignedUrlInput = {
  /** Type of the object being uploaded, asset or a file to share */
  bucketType: BucketType;
  /** Content type of the file. ex: image/jpeg */
  contentType: Scalars["String"]["input"];
  /** The path at which the user wants to fetch or upload the file */
  objectPath: Scalars["String"]["input"];
};

export type Tenant = {
  __typename: "Tenant";
  /** Tenant's Front End URL */
  appUrl: Scalars["String"]["output"];
  /** Client Id used for the tenant Id in Cognito */
  clientId: Scalars["String"]["output"];
  /** Id of the tenant set */
  id: Scalars["String"]["output"];
  /** Default language code of the tenant */
  languageCode: Scalars["String"]["output"];
  /** Name of the tenant */
  name: Scalars["String"]["output"];
  /** Short name for the tenant */
  shortCode: Scalars["String"]["output"];
  /** Tenant status which denotes, if tenant is live or is disabled */
  status: TenantStatus;
};

export enum TenantStatus {
  Disabled = "DISABLED",
  Live = "LIVE",
}

export type TenantsOutput = PaginatedQueryOutput & {
  __typename: "TenantsOutput";
  items: Array<Tenant>;
  total: Scalars["Int"]["output"];
};

/** Denotes a Tier Level. */
export type TierLevel = {
  __typename: "TierLevel";
  /** Amount of the tier level */
  amount: Scalars["Int"]["output"];
  /** ID of the tier level. */
  levelId: Scalars["String"]["output"];
};

export enum TimeFrame {
  Daily = "DAILY",
  Monthly = "MONTHLY",
  Weekly = "WEEKLY",
}

/** Data for tournament notifications */
export type TournamentUserNotificationData = {
  __typename: "TournamentUserNotificationData";
  /** User that replaced the notification's recipient in the tournament */
  demotingUser: Maybe<User>;
  /** GAME ID */
  gameId: Scalars["String"]["output"];
  /** Rank of the user in that tournament. */
  rank: Scalars["Int"]["output"];
  /** TOURNAMENT ID */
  tournamentId: Scalars["UUID"]["output"];
};

/** Denotes a transaction. */
export type Transaction = {
  __typename: "Transaction";
  /** Amount credited or debited to/from the user's wallet. */
  amount: Scalars["Float"]["output"];
  /** Coin type of the transaction. says gems or basic */
  coinType: CoinType;
  /** Date on which the transaction occurred */
  date: Scalars["DateTimeISO"]["output"];
  /** Date and time at which coins credited from the transaction will expire. All debit transaction will have value as `null` except for `EXPIRED` ones */
  expiryDate: Maybe<Scalars["DateTimeISO"]["output"]>;
  /** Game data if the transaction was performed in relation to a game */
  game: Maybe<Game>;
  /** Entry ID for the transaction. */
  id: Scalars["UUID"]["output"];
  /** Reference identifier of the transaction. E.g., for battles and tournaments it would be the battle id */
  referenceId: Maybe<Scalars["String"]["output"]>;
  /** Transaction type of the category */
  type: TransactionType;
};

/** Denotes the type of the transaction */
export enum TransactionType {
  AchievementReward = "achievement_reward",
  Battle = "battle",
  Bonus = "bonus",
  Challenge = "challenge",
  Expired = "expired",
  Free = "free",
  Inventory = "inventory",
  LoginReward = "login_reward",
  Manual = "manual",
  Other = "other",
  Subscription = "subscription",
  Topup = "topup",
  Tournament = "tournament",
  Welcome = "welcome",
}

/** Output from coinTransactions */
export type Transactions = PaginatedQueryOutput & {
  __typename: "Transactions";
  /** A list of transactions */
  items: Array<Transaction>;
  /** Total count of results */
  total: Scalars["Int"]["output"];
};

/** Filters for fetching transactions. */
export type TransactionsInput = {
  /** Type of wallet to get transactions of. */
  coinTypes: InputMaybe<Array<CoinType>>;
  /** Pagination input */
  page: InputMaybe<PaginatedQueryInput>;
};

/** Input for updating a featured card. */
export type UpdateFeaturedCardsInput = {
  /** List of featured cards to be updated */
  cards: Array<FeaturedCardInput>;
  /** Tenant ID */
  tenantId: Scalars["String"]["input"];
};

export type UpdateTenantInput = {
  /** Tenant's Front End URL */
  appUrl: InputMaybe<Scalars["String"]["input"]>;
  /** Cognito Client ID used for login by the tenant */
  clientId: InputMaybe<Scalars["String"]["input"]>;
  /** ID of the tenant to be updated */
  id: Scalars["String"]["input"];
  /** Default language code of the tenant. */
  languageCode: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Name of the tenant
   * Recommended Format: `FriendlyTenantName FriendlyCountryName`
   * Example: `Dialog Sri Lanka`
   */
  name: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Short name for the tenant
   * - Restricted to 3 characters
   * - Example: `dgl`, `vil`, etc.
   */
  shortCode: InputMaybe<Scalars["String"]["input"]>;
  /** Tenant status which indicates if tenant is live or is disabled. */
  status: InputMaybe<TenantStatus>;
};

export type User = {
  __typename: "User";
  /** Avatar set for the user */
  avatar: Scalars["String"]["output"];
  /** Banner of the user */
  banner: Scalars["String"]["output"];
  /** User's coin wallets */
  coinWallets: Array<CoinWallet>;
  /** User's account status */
  disabled: Scalars["Boolean"]["output"];
  /** User's email address */
  email: Maybe<Scalars["String"]["output"]>;
  /** Facebook's idp id */
  facebookId: Maybe<Scalars["String"]["output"]>;
  /** Google's idp Id */
  googleId: Maybe<Scalars["String"]["output"]>;
  /** ID of the user */
  id: Scalars["UUID"]["output"];
  /** Common IDP ID of the user. `null` when user signed up using phone or email */
  idpId: Maybe<Scalars["String"]["output"]>;
  /** IDP Provider Name. `null` when user signed up using phone or email */
  idpName: Maybe<Scalars["String"]["output"]>;
  /** User's inventories */
  inventories: InventoriesOutput;
  /** User's desired language */
  language: Maybe<Scalars["String"]["output"]>;
  /** User's metadata */
  metadata: Maybe<UserMetadata>;
  /** User's notifications */
  notifications: Maybe<Array<Maybe<UserNotification>>>;
  /** User's phone number */
  phone: Maybe<Scalars["String"]["output"]>;
  /** User's push subscription endpoint */
  pushSubscription: Maybe<Scalars["String"]["output"]>;
  /** User's transaction */
  transactions: Transactions;
  /** Username of the user */
  username: Scalars["String"]["output"];
  /** user's xp value */
  xp: Scalars["Int"]["output"];
};

export type UserInventoriesArgs = {
  input: InputMaybe<InventoriesInput>;
};

export type UserTransactionsArgs = {
  input: InputMaybe<TransactionsInput>;
};

export type UserMetadata = {
  __typename: "UserMetadata";
  desiredStreamResolution: Maybe<Scalars["Int"]["output"]>;
  hasReceivedWelcomeCoins: Scalars["Boolean"]["output"];
  hasSeenWelcomePopup: Scalars["Boolean"]["output"];
  lastSeenTransactionId: Maybe<Scalars["String"]["output"]>;
  onboarding: Maybe<Scalars["String"]["output"]>;
};

export type UserMetadataInput = {
  desiredStreamResolution: InputMaybe<Scalars["Int"]["input"]>;
  hasReceivedWelcomeCoins: InputMaybe<Scalars["Boolean"]["input"]>;
  hasSeenWelcomePopup: InputMaybe<Scalars["Boolean"]["input"]>;
  lastSeenTransactionId: InputMaybe<Scalars["String"]["input"]>;
  onboarding: InputMaybe<Scalars["String"]["input"]>;
};

/** Denotes a user Notification. */
export type UserNotification = {
  __typename: "UserNotification";
  /** Date and time at which the notification was created */
  createdAt: Maybe<Scalars["DateTimeISO"]["output"]>;
  /** Details about the notification. Format is based on notification type */
  data: UserNotificationData;
  /** Notification ID */
  id: Scalars["UUID"]["output"];
  /** Notification Type */
  type: UserNotificationType;
};

/** Possible format of notification data depending on the notification type */
export type UserNotificationData =
  | AchievementUserNotificationData
  | BattleUserNotificationData
  | FollowUserNotificationData
  | LeaderboardUserNotificationData
  | TournamentUserNotificationData;

/** Denotes the type of a notification. */
export enum UserNotificationType {
  AchievementReward = "ACHIEVEMENT_REWARD",
  BattleExpired = "BATTLE_EXPIRED",
  BattleExpiring = "BATTLE_EXPIRING",
  BattleInvitation = "BATTLE_INVITATION",
  BattleLost = "BATTLE_LOST",
  BattleWon = "BATTLE_WON",
  FollowedByUser = "FOLLOWED_BY_USER",
  GlobalLeaderboardEnded = "GLOBAL_LEADERBOARD_ENDED",
  GlobalLeaderboardMonthlyReminder = "GLOBAL_LEADERBOARD_MONTHLY_REMINDER",
  GlobalLeaderboardWeeklyReminder = "GLOBAL_LEADERBOARD_WEEKLY_REMINDER",
  TournamentCompletion = "TOURNAMENT_COMPLETION",
  TournamentDemotion = "TOURNAMENT_DEMOTION",
}

export type UserUpdateInput = {
  /** Banner to be set for user */
  banner: InputMaybe<Scalars["String"]["input"]>;
  /** To disable or enable the user. When value is `true` other fields cannot be used */
  disable: InputMaybe<Scalars["Boolean"]["input"]>;
  /** ID of the user to be updated. The field is only required when user update is being performed by ADMINs */
  id: InputMaybe<Scalars["UUID"]["input"]>;
  /** To set the desired language by the user */
  language: InputMaybe<Scalars["String"]["input"]>;
  /** To set push subscription value for the user */
  pushSubscription: InputMaybe<Scalars["String"]["input"]>;
  /** To move user to different tenant. Cannot be used with other update fields */
  tenantId: InputMaybe<Scalars["String"]["input"]>;
  /** New username for the user */
  username: InputMaybe<Scalars["String"]["input"]>;
};

export type UsersOutput = PaginatedQueryOutput & {
  __typename: "UsersOutput";
  items: Array<User>;
  total: Scalars["Int"]["output"];
};

export enum WalletType {
  Achievement = "achievement",
  Coin = "coin",
  Inventory = "inventory",
}
