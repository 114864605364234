import { PageRoutes } from "../constants/historyOnmo";
import { LeaderboardUserNotificationData } from "../legacyGraphql/graphql";
import {
  IHistoryOnmoProps,
  INavigateRouterParams,
  IPlayBattleSearchParams,
  ITabType,
} from "../types/historyOnmo";
import { OnmoLocation } from "./onmoLocation";
import { OnmoStorage } from "./onmoStorage";
import * as H from "history";

export class HistoryOnmo {
  static back = (
    history: H.History,
    { replace, destinationURL }: IHistoryOnmoProps = {},
  ) => {
    const prevPath = OnmoStorage.getPrevPath();
    const availablePrevPath =
      !!prevPath || window.location.pathname.includes("shop");
    const isFromOnmo =
      !window.document.referrer ||
      window.document.referrer.includes(window.location.hostname) ||
      !!prevPath;

    if (
      history.length > 2 &&
      availablePrevPath &&
      !destinationURL &&
      isFromOnmo
    ) {
      history.go(-1);
    } else {
      if (replace) {
        history.replace(destinationURL || PageRoutes.HomePage);
      } else {
        history.push(destinationURL || PageRoutes.HomePage);
      }
    }
  };

  static navigateTo = (
    history: H.History,
    { url, id, replace, state }: INavigateRouterParams,
    searchParams?: Record<string, string>,
  ) => {
    const searchQuery = new URLSearchParams(searchParams)?.toString();
    const target = `${url}${id || ""}?${searchQuery}`;

    if (replace) {
      history.replace(target, state);
    } else {
      history.push(target, state);
    }
  };

  static navigateToGameDetailPage = (
    history: H.History,
    gameId: string,
    replace?: boolean,
  ) => {
    this.navigateTo(history, {
      url: PageRoutes.GameDetail,
      id: `/${gameId}`,
      replace,
    });
  };

  static navigateToHomePage = (
    history: H.History,
    replace?: boolean,
    searchParams = {},
  ) => {
    this.navigateTo(
      history,
      { url: PageRoutes.HomePage, replace },
      searchParams,
    );
  };

  static navigateToFriendProfilePage = (
    history: H.History,
    friendId: string,
    state?: {
      userProfileFake?: {
        avatar: string;
        id: string;
        username: string;
        xp: number;
      };
    },
    replace?: boolean,
  ) => {
    this.navigateTo(history, {
      url: PageRoutes.UserProfile,
      id: `/${friendId}`,
      replace,
      state,
    });
  };

  static navigateToGamesPage = (history: H.History, replace?: boolean) => {
    this.navigateTo(history, {
      url: PageRoutes.Games,
      replace,
    });
  };

  static navigateToSelectGamePage = (
    history: H.History,
    searchParams: IPlayBattleSearchParams = {},
  ) => {
    this.navigateTo(
      history,
      {
        url: PageRoutes.FriendBattle,
      },
      searchParams as Record<string, string>,
    );
  };

  static navigateToMenuPage = (
    history: H.History,
    state?: { openAccountInfo?: boolean },
  ) => {
    this.navigateTo(history, { url: PageRoutes.Settings, state });
  };

  static navigateToOnmoShopPage = (history: H.History, replace?: boolean) => {
    this.navigateTo(history, { url: PageRoutes.OnmoShop, replace });
  };

  static isInGameSession = (pathname?: string) => {
    if (pathname)
      return pathname.includes("/play/") || pathname.includes("/live-stream");
    return (
      window.location.pathname.includes("/play/") ||
      window.location.pathname.includes("/live-stream")
    );
  };

  static getGameSessionType = () => {
    if (window.location.pathname.includes("/solo/")) return "Challenge";
    if (window.location.pathname.includes("/battle/")) return "Battle";
    if (window.location.pathname.includes("/tournament/")) return "Tournament";
  };

  static isBackToShop = () => {
    const prevPath = OnmoStorage.getPrevPath();
    return prevPath?.includes("onmo-shop") || prevPath?.includes("wallet");
  };

  static navigateToPlayBattlePage = (
    history: H.History,
    routerParams: { gameId: string; replace?: boolean; state?: unknown },
    searchParams: IPlayBattleSearchParams = {},
  ) => {
    const { gameId, replace, state } = routerParams;
    this.navigateTo(
      history,
      { url: PageRoutes.PlayBattle, id: `/${gameId}`, replace, state },
      searchParams as Record<string, string>,
    );
  };

  static navigateToPlayTournamentPage = (
    history: H.History,
    routerParams: { tournamentId: string; replace?: boolean; state?: unknown },
    searchParams = {},
  ) => {
    const { tournamentId, replace, state } = routerParams;

    this.navigateTo(
      history,
      {
        url: PageRoutes.PlayTournament,
        id: `/${tournamentId}`,
        replace,
        state,
      },
      searchParams,
    );
  };

  static navigateToPlaySoloPage = (
    history: H.History,
    routerParams: { gameId: string; replace?: boolean; state?: unknown },
    searchParams = {},
  ) => {
    const { gameId, replace, state } = routerParams;

    this.navigateTo(
      history,
      {
        url: PageRoutes.PlayChallenge,
        id: `/${gameId}`,
        replace,
        state,
      },
      searchParams,
    );
  };

  static navigateToGlobalSearchPage = (history: H.History) => {
    const isActiveGamesTab = OnmoLocation.isActiveGames(
      window.location.pathname,
    );

    this.navigateTo(history, {
      url: isActiveGamesTab ? PageRoutes.GamesSearch : PageRoutes.FriendsSearch,
    });
  };

  static navigateToMyProfilePage = (
    history: H.History,
    state?: {
      notificationData?: {
        battleId: string;
        gameId: string;
        battleType: string;
      };
      leaderBoardData?: LeaderboardUserNotificationData;
      achievement?: {
        type: string;
        tier: number;
      };
    },
    searchParams: ITabType = {},
    replace?: boolean,
  ) => {
    this.navigateTo(
      history,
      { url: PageRoutes.MyProfile, state, replace },
      searchParams as Record<string, string>,
    );
  };

  static navigateToHistoryTransactionsPage = (
    history: H.History,
    routerParams: {
      replace?: boolean;
      state?: unknown;
    },
  ) => {
    const { replace, state } = routerParams;
    this.navigateTo(history, {
      url: PageRoutes.TransactionHistorySearch,
      replace,
      state,
    });
  };

  static pushPrevPath = () => {
    const prevPath = OnmoStorage.getPrevPath() || "/";
    window.location.href = prevPath;
    OnmoStorage.removePrevPath();
  };

  static navigatePrePath = (history: H.History, replace?: boolean) => {
    const prevPath = OnmoStorage.getPrevPath() || "/";
    this.navigateTo(history, { url: prevPath as PageRoutes, replace });
  };

  static navigatePreGameSession = (history: H.History) => {
    const pathGameSession = OnmoStorage.getPathGameSession();
    const urlObj = new URL(pathGameSession || "");
    history.replace({
      pathname: urlObj.pathname,
      search: urlObj.search,
    });
  };

  static navigateToOnmoPay = (url: string) => {
    window.location.href = url;
  };

  static replaceQueryParams = (
    history: H.History,
    queryParams: string,
    state?: unknown,
  ) => {
    history.replace({
      search: queryParams,
      state: state,
    });
  };

  static navigateToFaqPage = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.Faq });
  };

  static navigateToPrivacyPolicyPage = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.PrivacyPolicy });
  };

  static navigateToTermAndConditionPage = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.TermAndCondition });
  };

  static navigateToCookiesPolicyPage = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.CookiesPolicy });
  };

  static navigateToEditProfilePage = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.EditProfile });
  };

  static navigateToLiveStreamPage = (
    history: H.History,
    searchParams = {},
    state?: unknown,
    replace?: boolean,
  ) => {
    this.navigateTo(
      history,
      {
        url: PageRoutes.LiveStream,
        replace,
        state,
      },
      searchParams,
    );
  };

  static navigateGoLive = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.GoLive });
  };

  static navigateToShopTransactions = (
    history: H.History,
    replace?: boolean,
  ) => {
    this.navigateTo(history, { url: PageRoutes.TransactionHistory, replace });
  };

  static navigateToShop = (
    history: H.History,
    searchParams = {},
    replace?: boolean,
  ) => {
    this.navigateTo(history, { url: PageRoutes.Shop, replace }, searchParams);
  };

  static navigateLevel = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.GemsInfo });
  };

  static isGamesPage = (history: H.History) => {
    return history.location.pathname.includes(PageRoutes.Games);
  };

  static isGlobalLeaderboardPage = (history: H.History) => {
    return history.location.pathname === PageRoutes.Leaderboard;
  };

  static isProfilePage = (history: H.History) => {
    return history.location.pathname === PageRoutes.MyProfile;
  };

  static isHomePage = (history: H.History) => {
    return history.location.pathname === PageRoutes.HomePage;
  };

  static isBundleDetailPage = (history: H.History) => {
    return history.location.pathname.includes(PageRoutes.BundleDetail);
  };

  static navigateToFeatureContestPage = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.FeaturedContest });
  };

  static navigateOnboarding = (history: H.History) => {
    this.navigateTo(history, { url: PageRoutes.Onboarding });
  };

  static navigateToBundleDetail = (
    history: H.History,
    routerParams: { bundleId: string; replace?: boolean; state?: unknown },
    searchParams = {},
  ) => {
    const { bundleId, replace, state } = routerParams;

    this.navigateTo(
      history,
      {
        url: PageRoutes.BundleDetail,
        id: `/${bundleId}`,
        replace,
        state,
      },
      searchParams,
    );
  };
}
