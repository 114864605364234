import {
  createSelector,
  createSlice,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import { map, filter } from "lodash-es";
import { AppDispatch, RootState } from "../store";
import { listTransactions } from "../legacyGraphql/resolvers/queries/transaction";
import { TABS } from "../constants/shopWallet";
import { HistoryTransactionFactory } from "../models/historyTransactionFactory";
import { LIMIT_LOAD_TRANSACTION } from "../constants/historyTransaction";
import { setNotificationError } from "./alert";
import {
  FullTransaction,
  Transaction_Type,
  TransactionActionType,
  TransactionCategory,
} from "../legacyGraphql/graphql";
import { TFunction } from "react-i18next";

interface ITransactionHistorySlice {
  listTransactionHistory: null | FullTransaction[];
  activeCategory: null | string;
  searchQuery: string;
  offset: number;
  isLoadedAll: boolean;
  isLoading: boolean;
}

const initialState = {
  listTransactionHistory: null,
  activeCategory: TABS[0].id,
  searchQuery: "",
  offset: 0,
  isLoadedAll: false,
  isLoading: false,
} as ITransactionHistorySlice;

export const resetTransactionHistoryState = createAction(
  "transactionHistory/resetState",
);

export const transactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState: initialState,
  reducers: {
    setActiveCategory: (state, action) => {
      const { activeCategory } = action.payload;
      state.activeCategory = activeCategory;
    },
    setSearchQuery: (state, action) => {
      const { query } = action.payload;
      state.searchQuery = query;
    },
    resetTransaction: (state) => {
      state.offset = 0;
      state.listTransactionHistory = null;
      state.isLoadedAll = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListTransactionHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchListTransactionHistory.fulfilled, (state, action) => {
        const offset = action.meta.arg.offset || 0;
        const items = action.payload;
        if (offset > 0 && state.offset >= offset + items.length) {
          return;
        }
        if (items) {
          state.listTransactionHistory =
            offset === 0
              ? items
              : [...(state.listTransactionHistory || []), ...items];
        }
        state.isLoadedAll = items.length < LIMIT_LOAD_TRANSACTION;
        state.offset = offset + items.length;
        state.isLoading = false;
      })
      .addCase(fetchListTransactionHistory.rejected, (state, action) => {
        state.isLoading = false;
        console.error(action.error);
      })
      .addCase(resetTransactionHistoryState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { setActiveCategory, setSearchQuery, resetTransaction } =
  transactionHistorySlice.actions;

export const fetchListTransactionHistory = createAsyncThunk(
  "transactionHistory/fetchListTransactionHistory",
  async (params: { offset: number }, { dispatch }) => {
    try {
      const { offset } = params;
      const transactions = await listTransactions(
        {
          limit: LIMIT_LOAD_TRANSACTION,
          offset,
        },
        { fetchPolicy: "network-only" },
      );
      return map(transactions.items, (transaction) => transaction);
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
      throw e;
    }
  },
);

const listTransactionHistory = (state: RootState) =>
  state.transactionHistory.listTransactionHistory;
const activeCategory = (state: RootState) =>
  state.transactionHistory.activeCategory;
const searchQuery = (state: RootState) => state.transactionHistory.searchQuery;

export const filterTransactions = createSelector(
  [listTransactionHistory, activeCategory],
  (listTransactionHistory, activeCategory) => {
    if (!listTransactionHistory || !activeCategory) return;
    const isAllCategories = activeCategory === TABS[0].id;
    const transactionFilter = filter(listTransactionHistory, (t) => {
      return activeCategory?.split(",")?.includes(t.transactionType);
    });

    return HistoryTransactionFactory.groupByDateTransactions(
      isAllCategories ? listTransactionHistory : transactionFilter,
    );
  },
);

export const searchTransactions = createSelector(
  [listTransactionHistory, searchQuery],
  (listTransactionHistory, searchQuery) => {
    if (!listTransactionHistory) return;
    const transactionFilter = filter(listTransactionHistory, (t) => {
      return t?.gameTitle?.toLowerCase()?.includes(searchQuery.toLowerCase());
    });
    if (transactionFilter?.length === 0) return;

    return HistoryTransactionFactory.groupByDateTransactions(transactionFilter);
  },
);

export const selectTitleTransactionActionType = (
  historyTransaction: FullTransaction,
) =>
  createSelector(
    (state: RootState) => state,
    () => {
      const { gameId, gameTitle, reference, transactionType } =
        historyTransaction;
      if (gameId) return gameTitle;
      if (reference?.includes("bonus")) return "common_Bonus Coins";

      switch (transactionType) {
        case TransactionActionType.CoinsPurchased:
          return "common_Top-up Coins";
        case TransactionActionType.SubscriptionCoinsReceived:
          return "common_Subscription";
        case TransactionActionType.TopupCoinsReceived:
          return "common_Top-up Coins";
        case TransactionActionType.BonusCoinsReceived:
        case TransactionActionType.CoinsExpired:
          return "common_Bonus Coins";
        default:
          return "...";
      }
    },
  );

export const selectStatusTransaction = (
  historyTransaction: FullTransaction,
  t: TFunction<"translation", undefined>,
  expireDateOn?: string,
) =>
  createSelector(
    (state: RootState) => state,
    () => {
      const { category, transactionType, expiryDate } = historyTransaction;

      const getExpiryStatus = () => {
        const currentDate = new Date().toISOString();
        return expiryDate > currentDate ? expireDateOn : t("battle_Expired");
      };

      const getCoinsPaidStatus = () => {
        switch (category) {
          case TransactionCategory.Battle:
            return t("history_Battle Fee");
          case TransactionCategory.Tournament:
            return t("history_Tournament Fee");
          default:
            return t("history_Challenge Fee");
        }
      };

      const getCoinsRefundedStatus = () => {
        switch (category) {
          case TransactionCategory.Battle:
            return t("history_Battle fee Refunded");
          case TransactionCategory.Tournament:
            return t("history_Tournament fee Refunded");
          default:
            return t("historyTransaction_Fee refunded");
        }
      };

      switch (transactionType) {
        case TransactionActionType.BonusCoinsReceived:
        case TransactionActionType.SubscriptionCoinsReceived:
        case TransactionActionType.TopupCoinsReceived:
          return getExpiryStatus();
        case TransactionActionType.CoinsPaid:
          return getCoinsPaidStatus();
        case TransactionActionType.CoinsWon:
          return category === TransactionCategory.Battle
            ? t("history_Battle Prize won")
            : t("history_Tournament Prize won");
        case TransactionActionType.CoinsRefunded:
          return getCoinsRefundedStatus();
        case TransactionActionType.CoinsExpired:
          return t("battle_Expired");
        default:
          return t("transactionDetails_Completed");
      }
    },
  );

export const selectIsPlusCoinCash = (type: string) =>
  createSelector(
    (state: RootState) => state,
    () => {
      return type === Transaction_Type.Credit;
    },
  );

export const selectGetLocaleDate = (date: string) =>
  createSelector(
    (state: RootState) => state,
    () => {
      const newDate = new Date(date);
      const formattedDate = newDate.toLocaleDateString();

      return formattedDate;
    },
  );

export default transactionHistorySlice.reducer;
