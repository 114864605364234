import { compact, filter, groupBy, map, orderBy, sortBy } from "lodash-es";
import {
  Inventory,
  InventoryBundleData,
  InventoryStatus,
} from "../../legacyGraphql/graphql";
import { INVENTORIES_ORDER } from "../../constants/shopWallet";

const getBundleById = (
  listBundlesInventories: Inventory[],
  bundleId: string,
) => {
  const bundle = listBundlesInventories?.find(
    (bundle) => bundle.id === bundleId,
  );
  return bundle;
};

const getBundleByInventory = (
  listBundlesInventories: Inventory[],
  inventoryId: string,
) => {
  const bundle = listBundlesInventories?.find((inventory) =>
    (inventory.data as InventoryBundleData).items.includes(inventoryId),
  );
  return bundle;
};

const getOwnedBundles = (userInventories: Inventory[]) => {
  const ownedBundles = userInventories.filter((i) => i.isBundle);
  return ownedBundles;
};

const getOwnedItemsGroupByCategory = (userInventories: Inventory[]) => {
  const ownedItemsGroupByCategory = groupBy(
    userInventories.filter((i) => !i.isBundle),
    "category",
  );
  return ownedItemsGroupByCategory;
};

const getBundlesNotPurchase = (listBundlesInventories: Inventory[]) => {
  const ownedItemsGroupByCategory = orderBy(
    filter(listBundlesInventories, {
      isPurchased: false,
      status: InventoryStatus.Available,
    }),
    ["updatedAt"],
    ["desc"],
  );
  return ownedItemsGroupByCategory;
};

const sortAvatars = (avatars: Inventory[]) => {
  const addNewTypeInventory = (inventory: Inventory) => {
    if (inventory.isPurchased) {
      return "Purchased";
    } else if (inventory.status === InventoryStatus.Restricted) {
      return "Premium";
    } else if (!inventory?.costs?.length) {
      return "Free";
    } else return "Paid";
  };

  const newAvatars = sortBy(
    avatars.map((avatar) => {
      return {
        ...avatar,
        type: addNewTypeInventory(avatar),
      };
    }),
    (item) => INVENTORIES_ORDER.indexOf(item.type),
  );
  return newAvatars;
};

const getAvatarNotPurchase = (listInventories: Inventory[][]) => {
  const avatarNotPurchase = orderBy(
    compact(
      map(listInventories, (inventories) => {
        const avatars = filter(inventories, (inventory) => {
          return (
            [InventoryStatus.Available, InventoryStatus.Restricted].includes(
              inventory.status,
            ) && !inventory.isPurchased
          );
        });

        const newAvatars = sortAvatars(avatars);
        if (newAvatars.length > 0) {
          return newAvatars;
        }
      }),
    ),
    ["updatedAt"],
    ["desc"],
  );
  return avatarNotPurchase;
};

export const Inventories = {
  getBundleById,
  getBundleByInventory,
  getOwnedBundles,
  getOwnedItemsGroupByCategory,
  getBundlesNotPurchase,
  getAvatarNotPurchase,
  sortAvatars,
};
